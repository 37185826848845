"use strict";
import swapElements from "@helpers/swapElements";
const { prestashop } = window;
if (prestashop) {
  prestashop.responsive = prestashop.responsive || {};
  prestashop.responsive.current_width = window.innerWidth;
  prestashop.responsive.min_width = 768;
  prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
}
export function toggleMobileStyles() {
  const { prestashop: prestashop2, Theme: { events } } = window;
  if (prestashop2.responsive.mobile) {
    Array.prototype.forEach.call(document.querySelectorAll("*[id^='_desktop_']"), (el) => {
      const source = document.querySelector(`#${el.id}`);
      const target = document.querySelector(`#${el.id.replace("_desktop_", "_mobile_")}`);
      if (target && source) {
        swapElements(source, target);
      }
    });
  } else {
    Array.prototype.forEach.call(document.querySelectorAll("*[id^='_mobile_']"), (el) => {
      const source = document.querySelector(`#${el.id}`);
      const target = document.querySelector(`#${el.id.replace("_mobile_", "_desktop_")}`);
      if (target && source) {
        swapElements(source, target);
      }
    });
  }
  prestashop2.emit(events.responsiveUpdate, {
    mobile: prestashop2.responsive.mobile
  });
}
export default function initResponsiveToggler() {
  const { prestashop: prestashop2 } = window;
  prestashop2.responsive = prestashop2.responsive || {};
  prestashop2.responsive.current_width = window.innerWidth;
  prestashop2.responsive.min_width = 768;
  prestashop2.responsive.mobile = prestashop2.responsive.current_width < prestashop2.responsive.min_width;
  window.addEventListener("resize", () => {
    const currentWidth = prestashop2.responsive.current_width;
    const minWidth = prestashop2.responsive.min_width;
    const screenWidth = window.innerWidth;
    const toggle = currentWidth >= minWidth && screenWidth < minWidth || currentWidth < minWidth && screenWidth >= minWidth;
    prestashop2.responsive.current_width = screenWidth;
    prestashop2.responsive.mobile = prestashop2.responsive.current_width < prestashop2.responsive.min_width;
    if (toggle) {
      toggleMobileStyles();
    }
  });
}
document.addEventListener("DOMContentLoaded", () => {
  if (prestashop.responsive.mobile) {
    toggleMobileStyles();
  }
});
