"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Modal } from "bootstrap";
import useProgressRing from "@js/components/useProgressRing";
const initCheckout = () => {
  const { prestashop } = window;
  const { Theme: { selectors, events } } = window;
  const { progressRing: ProgressRingMap, checkout: CheckoutMap } = selectors;
  const steps = document.querySelectorAll(CheckoutMap.steps.item);
  const actionButtons = document.querySelectorAll(CheckoutMap.actionsButtons);
  const { setProgress } = useProgressRing(ProgressRingMap.checkout.element, { steps: steps.length });
  const termsLink = document.querySelector(CheckoutMap.termsLink);
  const termsModalElement = document.querySelector(CheckoutMap.checkoutModal);
  const toggleStep = (content, step) => {
    const currentContent = document.querySelector(CheckoutMap.steps.current);
    currentContent == null ? void 0 : currentContent.classList.remove("step--current", "js-current-step");
    if (step) {
      const responsiveStep = document.querySelector(CheckoutMap.steps.specificStep(step.dataset.step));
      const shownResponsiveStep = document.querySelector(CheckoutMap.steps.shownResponsiveStep);
      shownResponsiveStep == null ? void 0 : shownResponsiveStep.classList.add("d-none");
      responsiveStep == null ? void 0 : responsiveStep.classList.remove("d-none");
    }
    content.classList.add("js-current-step");
  };
  actionButtons.forEach((button) => {
    const stepContent = document.querySelector(
      CheckoutMap.steps.specificStepContent(button.dataset.step)
    );
    button.addEventListener("click", (event) => {
      event.preventDefault();
      const triggerEl = document.querySelector(
        CheckoutMap.steps.backButton(button.dataset.step)
      );
      if (stepContent && triggerEl) {
        triggerEl.click();
        toggleStep(stepContent);
      }
    });
  });
  steps.forEach((step, index) => {
    const stepContent = document.querySelector(
      CheckoutMap.steps.specificStepContent(step.dataset.step)
    );
    if (stepContent) {
      if (stepContent.classList.contains("step--complete")) {
        step.classList.add("checkout__steps--success");
      }
      if (stepContent.classList.contains("step--current")) {
        step.classList.add("checkout__steps--current");
        const responsiveStep = document.querySelector(
          CheckoutMap.steps.specificStep(step.dataset.step)
        );
        const shownResponsiveStep = document.querySelector(CheckoutMap.steps.shownResponsiveStep);
        shownResponsiveStep == null ? void 0 : shownResponsiveStep.classList.add("d-none");
        responsiveStep == null ? void 0 : responsiveStep.classList.remove("d-none");
        if (setProgress) {
          setProgress(index + 1);
        }
      }
      if (stepContent.classList.contains("step--reachable")) {
        const button = step.querySelector("button");
        button == null ? void 0 : button.classList.add("btn-link");
        button == null ? void 0 : button.addEventListener("click", () => {
          if (setProgress) {
            setProgress(index + 1);
          }
          toggleStep(stepContent, step);
        });
      }
      if (stepContent.classList.contains("step--unreachable")) {
        const button = step.querySelector("button");
        button == null ? void 0 : button.setAttribute("disabled", "true");
        button == null ? void 0 : button.addEventListener("click", () => {
          toggleStep(stepContent, step);
        });
      }
    }
  });
  termsLink == null ? void 0 : termsLink.addEventListener("click", (event) => {
    event.preventDefault();
    if (termsModalElement) {
      const termsModal = new Modal(termsModalElement);
      const linkElement = event.target;
      let url = linkElement.getAttribute("href");
      if (url) {
        url += "?content_only=1";
        (() => __async(void 0, null, function* () {
          try {
            const response = yield fetch(url);
            const content = yield response.text();
            const contentElement = document.createElement("div");
            contentElement.innerHTML = content;
            const modalBody = termsModalElement.querySelector(selectors.modalBody);
            const sanitizedContent = contentElement.querySelector(selectors.pageCms);
            if (sanitizedContent && modalBody) {
              modalBody.innerHTML = sanitizedContent.innerHTML;
              termsModal.show();
            }
          } catch (e) {
            prestashop.emit(events.handleError, { eventType: "clickOnTermsLink", error: e });
          }
        }))();
      }
    }
  });
  $("body#checkout .step-current").each(function() {
    $("." + $(this).attr("id")).addClass("current");
  });
  $("body#checkout .step-complete").each(function() {
    $("." + $(this).attr("id")).addClass("complete");
    $("." + $(this).attr("id") + " .number").html('<i class="material-icons check small mt-1">check</i>');
  });
  $(".steps-header-container .steps-header li").on("click", function() {
    if ($("#js-checkout-process #" + $(this).data("target")).hasClass("step-reachable")) {
      $("#js-checkout-process .step-current").removeClass("step-current");
      $("#js-checkout-process ").removeClass("show");
      $("#js-checkout-process #" + $(this).data("target")).addClass("step-current");
      $("#js-checkout-process #" + $(this).data("target") + " .collapse").addClass("show");
    }
  });
  if ($("#checkout-personal-information-step").hasClass("step-complete")) {
    console.log($(".js-address-item.selected address.address__content").html());
    $("#js-checkout-recap-summary #checkout-personal-information-step .value").html(prestashop.customer.email);
    $("#js-checkout-recap-summary").show();
  }
  if ($("#checkout-addresses-step").hasClass("step-complete")) {
    $("#js-checkout-recap-summary #checkout-addresses-step .value").html($("#" + $("#adress-select-input").find(":selected").val()).html());
    $("#js-checkout-recap-summary #checkout-addresses-step").show();
  }
  if ($("#checkout-delivery-step").hasClass("step-complete")) {
    $("#js-checkout-recap-summary #checkout-delivery-step .value").html($("input.custom-control-input.js-input-delivery:checked").parent().parent().parent().children(".col-md-7").children(".custom-control-label").text());
    $("#js-checkout-recap-summary #checkout-delivery-step").show();
  }
  $("body#checkout #js-checkout-recap-summary .editico .material-icons").on("click", function() {
    $(".checkout-step.current").removeClass("current");
    $("#" + $(this).parent().parent().attr("id")).addClass("current");
    $("#" + $(this).parent().parent().attr("id") + " .collapse").addClass("show");
  });
  $("#pay-with-payment-option-1-form").removeAttr("style");
  $(".cart-total>span").append("TTC");
};
export default initCheckout;
