"use strict";
import { Collapse } from "bootstrap";
import { isHTMLElement } from "@helpers/typeguards";
export default () => {
  const { Theme } = window;
  const voucherCodes = document.querySelectorAll(Theme.selectors.cart.discountCode);
  voucherCodes.forEach((voucher) => {
    voucher.addEventListener("click", (event) => {
      event.stopPropagation();
      if (isHTMLElement(event.currentTarget)) {
        const code = event.currentTarget;
        const discountInput = document.querySelector(Theme.selectors.cart.discountName);
        const promoCode = document.querySelector(Theme.selectors.cart.promoCode);
        if (promoCode && discountInput) {
          const formCollapser = new Collapse(promoCode);
          discountInput.value = code.innerText;
          formCollapser.show();
        }
      }
      return false;
    });
  });
  function fixedCTACheckout() {
    var button = document.querySelector("#cart .checkout");
    var card = document.querySelector(".cart-right-wrapper");
    if (button && card) {
      if (card.getBoundingClientRect().top < 0) {
        button.classList.add("fixed-cta");
      } else {
        button.classList.remove("fixed-cta");
      }
    }
  }
  if (window.matchMedia("(max-width: 540px)").matches) {
    window.onscroll = function() {
      fixedCTACheckout();
    };
  }
  $(document).on("click", "#shoppingcart-content .info-wrapper-footer-line-link a", (e) => {
    e.preventDefault();
    e.stopPropagation();
    if ($("#shoppingcart-content .info-wrapper-footer-line-link a")) {
      var href = $("#shoppingcart-content .info-wrapper-footer-line-link a").attr("href");
      window.open(href, "_self");
    }
  });
  $(window).scroll(function() {
    var atc = document.querySelector(".product-add-to-cart");
    if (atc) {
      var atcOffset = atc.getBoundingClientRect();
      if (atcOffset) {
        var atcOffsetTop = atcOffset.top;
        if (atcOffsetTop < 0) {
          $(".fixed-product-actions").removeClass("d-none");
        } else {
          $(".fixed-product-actions").addClass("d-none");
        }
      }
    }
  });
  if ($("#checkout-addresses-step").hasClass("active") || $("#checkout-delivery-step").hasClass("active") || $("#checkout-payment-step").hasClass("active")) {
    $(".order-checkout").addClass("step2");
  } else {
    $(".order-checkout").removeClass("step2");
  }
  $(document).on("click", ".blockcart, .cart-close, #shoppingcart-modal-backdrop", (e) => {
    e.preventDefault();
    e.stopPropagation();
    $(".l-header .shoppingcart-content").attr("style", "top:" + $(".l-header").height() + "px");
    $("#shoppingcart-modal-backdrop").attr("style", "top:" + $(".l-header").height() + "px");
    $("#shoppingcart-content").addClass("active");
    if ($("#shoppingcart-content").hasClass("active")) {
      $("#shoppingcart-modal-backdrop").attr("style", "display:inherit!important");
      $("#minimized-trustbadge-98e3dadd90eb493088abdc5597a70810").attr("style", "display:none!important");
    } else {
      $("#shoppingcart-modal-backdrop").attr("style", "display:none!important");
      $("#shoppingcart-content .alert-add-to-cart").attr("style", "display:none!important");
      $("#minimized-trustbadge-98e3dadd90eb493088abdc5597a70810").attr("style", "display:block!important");
    }
  });
  $(document).on("click", ".cart-close, #shoppingcart-modal-backdrop", (e) => {
    e.preventDefault();
    e.stopPropagation();
    $("#shoppingcart-content").removeClass("active");
    if ($("#shoppingcart-content").hasClass("active")) {
      $("#shoppingcart-modal-backdrop").attr("style", "display:inherit!important");
      $("#minimized-trustbadge-98e3dadd90eb493088abdc5597a70810").attr("style", "display:none!important");
    } else {
      $("#shoppingcart-modal-backdrop").attr("style", "display:none!important");
      $("#shoppingcart-content .alert-add-to-cart").attr("style", "display:none!important");
      $("#minimized-trustbadge-98e3dadd90eb493088abdc5597a70810").attr("style", "display:block!important");
    }
  });
  if (window.matchMedia("(max-width: 600px)").matches) {
    $(".header__top-desktop #_cart").empty();
    $(".product-variants .btn-group").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        }
      ]
    });
    if (window.matchMedia("(max-width: 600px)").matches) {
      $(".reassurance_footer").slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots: true
      });
    }
    $(".rea_wrapper").slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });
  }
  $(".productList_mobile").slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    dots: true
  });
  $(".subcategories.slick-subcategories").slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  });
  $(".slick-related-product").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  });
  $("#ts_product_widget").on("click", function() {
    var _a;
    var tag = $("#reviews");
    $("html,body").animate({ scrollTop: (_a = tag == null ? void 0 : tag.offset()) == null ? void 0 : _a.top }, "slow");
  });
  $(".product-related-thumbs.slick-colors").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  });
  $(".product-variants .slick-size").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  });
  $("#blog_list_1-7").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });
  $(".homecategories").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  });
  $(".featured-categories-list").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  $("#conditions_to_approve input").on("click", function() {
    alert("tesst");
    $("#payment-confirmation .btn").toggleClass("disabled");
  });
  $("#btn_lang_select").on("click", function() {
    $(".wrapper_lang_select").toggleClass("d-none");
    $(".wrapper_lang_select").toggleClass("open_select_lang");
  });
  $("body#checkout #js-checkout-recap-summary .editico").on("click", function() {
    console.log("test");
    $("#js-checkout-process .step.step-current").removeClass("step-current").removeClass("-current");
    $(".steps-header .checkout-step").removeClass("current");
    $("#js-checkout-process #" + $(this).parent().attr("id")).addClass("step-current");
    $('li[data-target="' + $(this).parent().attr("id") + '"]').addClass("current");
  });
  $("body#checkout .address-change-select").on("change", function() {
    if ($(this).find(":selected").val() === "new_address") {
      window.location.href = $(this).find(":selected").data("url");
    }
  });
  $(".mm_menus_li.mm_sub_align_left.mm_has_sub").on("click", function() {
    var hrefMenu = $(this).children("a").attr("href");
    if (hrefMenu) {
      window.location.replace(hrefMenu);
    }
  });
  const { prestashop } = window;
  const { events } = Theme;
  $("body").on(
    "change",
    `${Theme.selectors.listing.searchFilters} input[data-search-url]`,
    (event) => {
      prestashop.emit(events.updateFacets, parseSearchUrl(event));
    }
  );
  const parseSearchUrl = function(event) {
    if (event.target.dataset.searchUrl !== void 0) {
      return event.target.dataset.searchUrl;
    }
    if ($(event.target).parent()[0].dataset.searchUrl === void 0) {
      throw new Error("Can not parse search URL");
    }
    return $(event.target).parent()[0].dataset.searchUrl;
  };
  $(".facet .custom-checkbox").on("click", function() {
    var parent = $(this).parent().parent().parent().parent().parent();
    parent.find(".collapse").toggleClass("show");
    parent.find(".add").toggleClass("d-none");
    parent.find(".remove").toggleClass("d-none");
  });
};
